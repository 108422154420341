export default {
  taxiosReceipt: 'Taxios receipt',
  receipt: 'Receipt',
  noReceipt: 'No Receipt found for this order',
  time: 'Time',
  speed: 'Speed',
  coordinates: 'Coordinates',
  title: 'Taxios Admin',
  entries: 'entries',
  email: 'Email',
  emails: 'Emails',
  password: 'Password',
  select: 'Select',
  cancel: 'Cancel',
  close: 'Close',
  submit: 'Submit',
  edit: 'Edit',
  confirm: 'Confirm',
  changeOil: 'Change oil',
  save: 'Save',
  activate: 'Activate',
  deactivate: 'Deactivate',
  selectOne: 'Select',
  saveChanges: 'Save Changes',
  startTyping: 'Start typing',
  download: 'Download',
  loadData: 'Load Data',
  required: 'Cannot be empty',
  search: 'Search',
  from: 'From',
  to: 'To',
  anyDriver: 'Any Driver',
  allOrders: 'All Orders',
  driver: 'Driver',
  vehicle: 'Vehicle',
  months: 'months',
  month: 'month',
  weeks: 'weeks',
  today: 'Today',
  yesterday: 'Yesterday',
  days: 'Days',
  everyDay: 'Every Day',
  years: 'Years',
  minute: 'Minute',
  minutes: 'Minutes',
  minutes2: 'Minutes',
  hour: 'Hour',
  hours: 'Hours',
  seconds: 'Seconds',
  unlimited: 'Unlimited',
  defaultAllDriver: 'Any driver',
  true: 'Yes',
  false: 'No',
  enabled: 'Enabled',
  disabled: 'Disabled',
  noLimit: 'No Limit',
  uploadNewPicture: 'Upload new',
  cropImage: 'Crop Image',
  crop: 'Confirm',
  remove: 'Remove',
  mustBeValidEmail: 'Must be valid email',
  fieldRequired: 'Field is required',
  minCharacters: 'Min 5 characters',
  minCharacters1: 'Min 1 character',
  valueMustBeAtLeastOne: 'Must be a number higher than 1',
  valueMustBeAtLeastMinus1: 'Must be a number higher than -1',
  mustBeBoolean: 'Must be true or false',
  mustBeNumber: 'Must be a number between 1 and 20',
  mustBePhoneNumber: 'Phone number must be in the format +421XXXXXXXXX',
  voiceRecordingOrFromToIsRequired: 'Either recording or From & To address is required',
  radioSettings: 'Radio Settings',
  speak: 'Speak',
  speaking: 'Speaking',
  radioMode: 'Radio mode',
  toggleInterval: 'Toggle interval',
  browser_not_supported: 'Browser not supported - please use Google Chrome or Chromium browsers on Desktop',
  CzechRepublic: 'Czech Republic',
  Slovakia: 'Slovakia',
  Poland: 'Poland',
  Hungary: 'Hungary',
  Hungarian: 'Hungarian',
  Slovak: 'Slovak',
  Polish: 'Polish',
  Czech: 'Czech',
  setupNotifications: 'Setup Notifications',
  addEmail: 'Add email',
  enterEmail: 'Enter email',
  setupEmails: 'Setup Emails',
  noEmails: 'No emails have been set up to receive notifications',
  errorMessages: {
    invalidCredentials: 'Invalid email or password',
    genericError: 'An error occurred. Status code: {status}',
    socketDisconnectedPleaseRefreshPage: 'Disconnected from server, please refresh the page',
    notFound: 'We couldn`t find the page you are looking for.',
    404: 'Page Not Found ⚠️'
  },
  orderStatuses: {
    INCOMING: 'Incoming',
    CANCELLED: 'Cancelled',
    ANSWERED: 'Answered',
    PROGRESS: 'In Progress',
    COMPLETED: 'Completed',
    ANSWEREDFORWARDED: 'Forwarded',
    UNANSWERED: 'Missed',
    OWNORDER: 'Own Order',
    AWAITINGCUSTOMER: 'Waiting for customer',
    PLANNED: 'Planned',
    PROCESSED: 'Processed',
    CUSTOMERAPPCREATED: 'Created',
    TIMEDOUT: 'Missed',
    'OFFLINE-MISSED': 'OFFLINE'
  },
  driverStatuses: {
    free: 'Free',
    occupied: 'Occupied',
    onBreak: 'On Break',
    onCall: 'On Call'
  },
  login: {
    header: 'Please sign-in to your account and start the adventure',
    login: 'Login'
  },
  menu: {
    dividers: {
      vehiclesAndDrivers: 'Vehicles and Drivers',
      phones: 'Phones',
      myCompany: 'My Company',
      app: 'Application'
    },
    tooltip: {
      vehicles: 'Here you can create and edit the vehicles that your drivers will be using',
      drivers: 'Here you can create and edit driver profiles. These profiles will be used for their logins, and you can also set limits on calls and orders for each driver',
      vehiclePhones: 'Here you need to set up the phone numbers that your drivers will use to answer customer calls',
      phoneBook: 'Manage your customer contacts: save customers to assign favorite drivers or block customers to prevent calls',
      switchBoard: 'View and manage the main line for the taxi service. Ensure all taxi phones are forwarded here for drivers to answer calls. Set up incoming call settings for the company',
      taxiosSentinel: 'Monitoring taxi position, MOT status, emissions, and more.'
    },
    dashboard: 'Dashboard',
    statistics: 'Statistics',
    orders: 'Orders',
    map: 'Map',
    vehicles: 'Vehicles',
    drivers: 'Drivers',
    phonesInVehicles: 'Vehicle Phones',
    phoneBook: 'Phone Book',
    phoneSwitchboard: 'Taxi Switchboard',
    invoices: 'Invoices',
    settings: 'Settings',
    logout: 'Logout',
    help: 'Help',
    customerApp: 'Customer App',
    taxiosDriver: 'Taxios Driver App',
    fareSettings: 'Fare Settings',
    vrpSK: 'VRP (SK)',
    taxiosSentinel: 'Taxios Sentinel',
    notifications: 'Notifications'
  },
  dashboard: {
    loggedInDrivers: 'Drivers online',
    viewingDataFor: 'Viewing statistics for:',
    completed: 'completed',
    cancelled: 'cancelled',
    total: 'total',
    todayCompleted: 'Today Completed',
    todayCancelled: 'Today Cancelled',
    todayTotal: 'Today Total',
    planned: 'Planned',
    welcomeBack: 'Welcome back',
    welcomeText: 'We have prepared new dashboard that is full of new features and helps you manage you taxi service much better! Enjoy it and let us know how we doing',
    analyticsMonthlyView: {
      header: 'Order Count Analysis: Yearly and Monthly Breakdown'
    }
  },
  orders: {
    header: 'Orders view',
    openOrderDetailModal: 'Order detail',
    displayOnlyPlannedOrders: 'Display planned orders only',
    saveCustomer: 'Save customer',
    editCustomer: 'Edit customer',
    cancelPlannedOrder: 'Cancel planned order',
    cancelPlannedOrderText: 'Are you sure you want to cancel this planned order?',
    addOrder: 'Add order',
    search: {
      customerPhoneNumber: 'Customer (number or name)',
      driverName: 'Driver Name',
      timeFrom: 'From',
      timeTo: 'To',
      orderStatus: 'Order Status',
      timeRange: 'Date / Time',
      range: {
        today: 'Today',
        yesterday: 'Yesterday',
        last7: 'Last 7 Days',
        thisMonth: 'This Month',
        lastMonth: 'Last Month',
        custom: 'Custom Range'
      }
    },
    itemsPerPage: 'Items per page',
    orderId: 'Order ID',
    orderStarted: 'Start',
    orderCompleted: 'Completed',
    length: 'Length',
    orderStatus: 'Status',
    driver: 'Driver',
    customer: 'Customer',
    options: 'Options',
    recordsFound: 'records found',
    callRecording: 'Call recording',
    from: 'Pickup',
    to: 'Destination',
    ownOrder: 'Own Order',
    modal: {
      header: 'Order detail',
      order: 'Order',
      incomingCall: 'Incoming call',
      tryingDifferentDriver: 'Trying different driver',
      unansweredCall: 'Unanswered call',
      orderCancelled: 'Order cancelled',
      callAnswered: 'Call answered',
      forwardedOrder: 'Order forwarded',
      orderInProgress: 'In progress',
      orderCompleted: 'Order completed',
      didNotAnswered: 'Did not answer',
      ownOrder: 'Own Order',
      customer: 'Customer',
      driver: 'Driver',
      toDriver: 'To driver',
      fromDriver: 'From driver',
      plannedTime: 'Planned for',
      sentToDriver: 'Sent to driver',
      plannedOrder: 'Order planned',
      recovered: 'Recovered',
      adminCreated: 'Admin created',
      dispatcherCreated: 'Dispatcher created',
      reminder: 'Reminder',
      customerCall: 'Customer call',
      driverCall: 'Driver call',
      cancelReason: 'Cancel reason',
      longWait: 'Long Wait',
      noShow: 'No Show',
      mistake: 'Mistake',
      infoOnly: 'Info Only',
      noVehicle: 'No Vehicle',
      generic: 'Not specified',
      driverCalledCustomer: 'Driver called customer',
      driverWasBusyWhenCallCame: 'Driver was busy when the call came',
      driverWasFreeWhenCallCame: 'Driver was free when the call came',
      status: 'Status',
      tabs: {
        ledger: 'Timeline',
        detail: 'Map & Details',
        order: 'Receipts'
      }
    },
    manualOrderModal: {
      header: 'New Order',
      customerPhoneNumber: 'Customer Phone Number',
      plannedOrderFlag: 'Planned Order Flag',
      from: 'From',
      to: 'To',
      driver: 'Driver',
      note: 'Note',
      plannedToTimeStamp: 'Planned To Time Stamp',
      remindBeforeStartTimeInMinutes: 'Remind Before Start Time In Minutes',
      startsNow: 'order starts right now',
      startsLater: 'order starts later',
      recording: 'Recording',
      record: 'Record',
      delete: 'Delete',
      save: 'Save',
      stop: 'Stop',
      recordingAdded: 'Recording added'
    }
  },
  statistics: {
    labelDateRange: 'Date rage to display statistics',
    completed: 'Completed Orders',
    cancelled: 'Cancelled Orders',
    total: 'Total',
    averageOrderTime: 'Average order time',
    dailyOrdersHeader: 'Order Count Analysis: Daily overview cancelled vs completed',
    completedOrderPerDriverHeader: 'Completed Orders Per Driver Analysis',
    completedOrderPerDriverVsWorkedHoursHeader: 'Completed Orders Per Driver vs Worked hours Analysis',
    workedHoursPerDriver: 'Working hours per driver',
    someStatsNotVisibleMdAndDown: 'Some statistics are not visible on mobile.',
    worstVsBestDriverHeader: 'Worst vs Best driver for today'
  },
  taxiDrivers: {
    header: 'Taxi Drivers',
    picture: 'Picture',
    name: 'Name',
    email: 'Email',
    usingPhone: 'Using Phone',
    loggedIn: 'Logged In',
    lastLogin: 'Last Login',
    vehicle: 'Using Vehicle',
    maxOrders: 'Max Orders',
    allowForwards: 'Allow Forwards',
    disableCalls: 'Disable Calls',
    password: 'Password',
    options: 'Options',
    addDriver: 'Add driver',
    currentlyHave: 'You have',
    drivers: 'drivers',
    maxNumOfConcurrentOrdersDescription: 'Max number of concurrent orders that driver can have at same time',
    allowForwardsFromOthersWhenMaxOrdersReachedDescription: 'Allow other drivers to forward orders to the driver who has reached max number of the orders (Max Orders field has to be activated)',
    disableCallsDescription: 'Driver will have incoming calls disabled, other can still forward order to him and he will be able to call customer from his active order',
    logoutDriver: 'Logout Driver',
    notLoggedIn: 'Not Logged In',
    youHaveLoggedOutDriver: 'has been logged out',
    vcrCompany: 'Belongs to VRP2 register',
    selectVCR: 'Select VRP2 register',
    vcr: 'VRP2 register',
    modal: {
      titleAdd: 'Add Driver',
      titleEdit: 'Edit Driver',
      removeDriver: 'Remove Driver'
    }
  },
  taxiVehicles: {
    header: 'Taxi Vehicles',
    addVehicle: 'Add Vehicle',
    name: 'Name',
    plate: 'Plate',
    model: 'Model',
    manufacturer: 'Manufacturer',
    year: 'Year',
    color: 'Color',
    fuel: 'Fuel',
    currentDriver: 'Current Driver',
    seats: 'Seats',
    options: 'Options',
    currentlyHave: 'You have',
    vehicles: 'vehicles',
    removeDriverFromACarDescription: 'This will sign-out the driver and free the car!',
    youHaveRemovedDriverFromVehicle: 'Driver has been detached from the vehicle',
    vehicleKm: 'Kilometers',
    vehicleKm2: 'Kilometers',
    lastOilChange: 'Oil changed',
    vignette: 'Vignette',
    mot: 'MOT',
    motStatus: 'MOT',
    emissionsStatus: 'Emissions',
    insurance: 'Insurance',
    lastOilChange2: 'Last oil change',
    newOilChange: 'Oil change',
    oilChangeIntervals: 'Oil change intervals',
    howMuchKmOilChange: 'At how much Km has the oil been changed',
    dateWhenOilChanged: 'Oil change date',
    vehicleData: 'General vehicle data',
    vehicleHistory: 'Vehicle position history',
    driverHistory: 'Driver history',
    sentinelNotificationEmails: 'Sentinel Notification Emails',
    sentinelNotificationEmailsDescription: 'Enter email addresses to receive notifications about expiring MOT, emissions, vignette, and insurance',
    validFrom: 'Valid From',
    validTill: 'Valid Till',
    lastCheck: 'Last check',
    timeDifference: 'Time difference between from and to can be maximum up to 24 hours',
    noVignette: 'No Vignette, sync with government websites is done every 24 hours but sometimes can take up to 5 days.',
    motCheck: 'MOT check is done every 24 hours but sometimes can take up to 5 days.',
    vcrCompany: 'Belongs to VRP2 register',
    selectVCR: 'Select VRP2 register',
    vcr: 'VRP2 register',
    modal: {
      removeVehicle: 'Remove Vehicle',
      titleEdit: 'Edit Vehicle',
      titleAdd: 'Add Vehicle',
      driverLogout: 'Logout driver and free the vehicle?',
      willBeLoggedOut: 'will be logged out',
      willBeFreed: 'will be freed'
    }
  },
  taxiPhones: {
    header: 'Vehicle Phones',
    addPhone: 'Add phone',
    name: 'Name',
    phoneNumber: 'Phone Number',
    registered: 'Registered',
    lastUpdated: 'Last used',
    note: 'Note',
    phoneId: 'Phone ID',
    version: 'Version',
    options: 'Options',
    modal: {
      titleAdd: 'Add Phone Number',
      titleEdit: 'Edit Phone Number',
      removePhoneNumbers: 'Remove Phone Number'
    }
  },
  taxiPhoneDirectory: {
    header: 'Phone Book',
    addPhone: 'Save new customer',
    name: 'Name',
    phoneNumber: 'Phone Number',
    favoriteDriver: 'Favorite Driver',
    note: 'Note',
    blocked: 'Blocked',
    options: 'Options',
    modal: {
      titleAdd: 'Save new customer',
      titleEdit: 'Edit saved customer',
      doesNotHaveFavoriteDriver: 'No favorite driver'
    }
  },
  taxiSwitchBoard: {
    header: 'Taxi Switchboard',
    headerStatistics: 'Taxi Switchboard Statistics',
    taxiNumber: 'Phone Number',
    phoneNumberDescription: 'Phone number where you should forward all the calls coming to your company',
    callMaxTimeout: 'Phone ring timeout',
    fallBackNumber: 'Fallback phone number',
    required: 'Field is required',
    callMaxTimeoutError: 'Timeout must be between 10 and 20 seconds',
    fallBackNumberError: 'Number must start with +',
    onlyLogOrderAndHangupCall: 'Only log order and hangup call',
    callFallbackNumberAndLogOrderAsMissed: 'Call fallback number and log order',
    onlySendDiagLogEmail: 'Only send diag log email (DON`T USE)',
    maxRetryBeforeCallingFallBackNumber: 'Max driver select retry before calling fallback number',
    maxRetryBeforeCallingFallBackNumberError: 'Max retry must be between 2 and 10',
    whatToDoWhenNoDriverLoggedIn: 'What to do when no driver is logged in or max attempts have been reached',
    maxRetryBeforeCallingFallBackNumberDescription:
      'How many times should system try to find available driver before calling fallback number. Mind that this number is multiplier of seconds "Phone ring timeout" which means e.g. 10 seconds x 3 = 30 seconds ringing to drivers before attempting to call fallback number.',
    whatToDoWhenNoDriverLoggedInCategory: 'What to do when no driver is logged in the company',
    driversSelection: 'Drivers Selection'
  },
  taxiInvoices: {
    header: 'My Invoices',
    invoiceName: 'Invoice Name',
    invoiceNumber: 'Invoice Number',
    periodMonth: 'Period Month',
    issueDate: 'Issue Date',
    paymentDue: 'Payment Due',
    paid: 'Paid Date',
    totalWithoutTax: 'Total Without Tax',
    taxAmount: 'Tax Amount',
    total: 'Total',
    options: 'Options',
    unpaid: 'Unpaid',
    info: 'Invoices are being sent to your email box (specified in',
    settings: 'settings',
    inTabInvoiceDetails: 'in tab invoice details)',
    everyMonth: 'on 15th every month and have to be paid by 15th day (usually on 1st of the month which is being invoiced)'
  },
  taxiosCustomerApp: {
    header: 'Taxios Customer App',
    pleaseContactUs1: 'If you with to have you own application (of course with your logo and your colors : available on Apple and Android Phones ) where your customers can order taxi without calling you, please contact us via email ',
    pleaseContactUs2: 'and we will help you make it happen'
  },
  taxiosDriverApp: {
    header: 'Taxios Driver App Settings',
    minLoggedDrivers: 'Minimum Logged Drivers',
    maxDriversOnBreak: 'Maximum Drivers on Break',
    maxDriversDisabledCalls: 'Maximum Drivers with Disabled Calls',
    showEachOtherStatistics: "Show Each Other's Statistics",
    showEachOtherStatisticsDescription: 'If "Yes" is selected, drivers can view each other’s stats in the side menu under "Our Drivers". If "No" is selected, the stats are not visible to them.',
    voiceChatButtonMode: 'Voice Chat Button Mode',
    voiceChatButtonModeDescription: 'When "PTT" is selected, the driver must press and hold to speak, release to stop. When "TOGGLE" is selected, a single press activates voice chat for a set duration before automatically stopping.',
    voiceChatToggleTimeLimiter: 'Voice Chat Toggle Time Limiter',
    voiceChatToggleTimeLimiterDescription: 'Sets the duration (in seconds) for how long the voice chat remains active in TOGGLE mode after pressing the button.',
    maxAllowedCancels: 'Maximum Allowed Cancels',
    maxAllowedCancelsDescription: 'Limits how many times a driver can cancel an order and recover their 1st place in the queue. After reaching the limit, they remain at the bottom of the queue and must wait for their next turn.',
    maxAllowedForwards: 'Maximum Allowed Forwards',
    maxAllowedForwardsDescription: 'Limits how many times a driver can forward an order to a colleague while recovering their 1st place in the queue. After reaching the limit, they stay at the bottom of the queue and must wait for their next turn.',
    maxAllowedMissedCalls: 'Maximum Allowed Missed Calls',
    maxAllowedMissedCallsDescription: 'Limits how many calls a driver can miss before being dropped to the bottom of the queue. After reaching the limit, the driver must wait for their next turn.',
    maxAllowedTransactionsTogether: 'Maximum Allowed Transactions Together',
    maxAllowedLengthOfOrderInSecondsToResetQueue: 'Maximum Allowed Length of Order (Seconds) to Reset Queue',
    maxAllowedLengthOfOrderInSecondsToResetQueueDescription:
      'Sets the time limit (in seconds) within which a driver can cancel an order and be moved back to their original 1st place in the queue. If the driver works on the order longer than this limit, cancelling the order will not reset their position in the queue.',
    pushToTalk: 'Push To Talk',
    toggleToTalk: 'Press once then talk until timer',
    driversManagement: 'Drivers settings',
    voiceChatSettings: 'Voice chat settings',
    phoneQueueSettings: 'Phone queue settings',
    lastUpdated: 'Last update',
    showCustomerNumberToTheDriver: 'Display customer number to the driver',
    showCustomerNumberToTheDriverDescription: 'If "Yes" is selected, drivers can see the customer`s number in the app. If "No" is selected, they will only see a button to call back.',
    anybodyCanEditPlannedOrder: 'Any driver can edit planned orders',
    anybodyCanEditPlannedOrderDescription: 'If "Yes" is selected, any driver can edit any planned order. If "No" is selected, only the admin or the driver who created the order can edit it.',
    allowDriverToSaveCustomer: 'Allow driver to save customer',
    allowDriverToSaveCustomerDescription: 'If "Yes" is selected, drivers will be allowed to save customers phone numbers to your Phone Book.',
    allowDriverToBlockCustomer: 'Allow driver to block customer',
    allowDriverToBlockCustomerDescription: 'If "Yes" is selected, drivers will have the option to block the customers phone number when saving it to your Phone Book. If a customer is blocked, they will not be able to reach your taxi service the next time they call.',
    displayDriversCallRecordings: 'Allow drivers to play call recordings',
    displayDriversCallRecordingsDescription: 'If "Yes" is selected, drivers will be able to play call recordings in the app.',
    requireReasonToCancelOrder: 'Require reason to cancel order',
    requireReasonToCancelOrderDescription: 'If "Yes" is selected, drivers will be required to provide a reason when cancelling an order.'
  },
  settings: {
    admins: 'Administrators',
    companyDetails: 'Company Details',
    invoiceDetails: 'Invoice Details',
    myInvoices: 'My Invoices',
    licenses: 'License Charges',
    name: 'Name of taxi service',
    adminName: 'Admin Name',
    companyName: 'Company Name',
    companyAddress: 'Address',
    companyAddress2: 'Address 2',
    companyAddress3: 'Address 3',
    companyCity: 'City',
    companyPostCode: 'Post Code',
    companyEmail: 'Email',
    companyPhone: 'Phone',
    companyRegisteredFrom: 'Registered From',
    companyCountry: 'Country',
    companyIdentificationNumber: 'Identification Number',
    companyTaxNumber: 'Tax Number',
    invoiceLanguage: 'Invoice Language',
    password: 'Password',
    role: 'Role',
    email: 'Email',
    options: 'Options',
    admin: 'Admin',
    dispatchAgent: 'Dispatch Agent',
    lastLoggedIn: 'Last login',
    addAdmin: 'Add new admin',
    vehicles: 'Vehicles',
    licensePrice: 'Standard price',
    perVehicle: '/ per vehicle',
    vehicleLicense: 'Vehicle license',
    vehicleLicenseInfo: 'Adding and removing vehicle licenses is automatic. The number of licenses is the same as the number of vehicles you use. To adjust the number of licenses, you can change the number of vehicles by pressing the button below.',
    manageMyVehicleLicenses: 'Modify vehicles',
    countAtPrice: 'You have {count} vehicles at price: {price} €',
    totalLicenses: 'Total Licenses: {count}',
    totalPrice: 'Total Price: {price} €',
    dispatcherLicense: 'Dispatcher License',
    dispatcherInfo: 'Manual order management using a fixed dispatcher. Unfortunately, the activation of this feature is not currently available. Please contact our technical support.',
    yourPrice: 'Your price: free (included in the starter bundle)',
    fleetManagerLicense: 'Fleet Manager License (Commander)',
    fleetManagerInfo: 'Managing vehicles with a fleet manager allows you to activate notifications for upcoming service events or alerts for highway toll sticker expiration.',
    callRecordingLicense: 'Call Recording License',
    callRecordingInfo: 'Automatic recording of incoming calls to improve the quality of your services as well as for detailed order analysis. Call recordings are available in the order details for 90 days.',
    callRecordingAlert:
      'Warning! By purchasing and using this product, you are obliged to inform your customers about call recordings. You must also comply with laws and regulations regarding data protection. Taxios s.r.o. assumes no responsibility for any violations of laws and regulations regarding data protection by you. To enable call recording notifications for customers, please contact technical support!',
    modal: {
      titleAdd: 'Add Admin',
      titleEdit: 'Edit Admin',
      removeAdmin: 'Remove Admin'
    }
  },
  taxiosVCR: {
    header: 'VRP 2 Integration',
    subheader: '(VRP is Slovakia only - if your company isn`t operated from Slovakia, please contact us and we will fix it for you)',
    enableVRP: 'Enable VRP integration in to Taxios Driver app?',
    VRPMethod: 'VRP 2 Execution Method',
    openVRPApp: 'Open VRP2 on order completed',
    directIntegration: 'VRP entry is submitted on you behalf by our service',
    VRPUserName: 'VRP 2 User Name',
    VRPPassword: 'VRP 2 Password',
    vrpAppOpenInstruction: 'When an order is completed, the Taxios Driver app will automatically open the VRP2 application, allowing the driver to create an entry, print a receipt, and more.',
    vrpDirectInstruction:
      'When an order is completed, the driver will be prompted to enter the fare cost (ensure that Fare Settings are configured!). We will then send this information to VRP 2 and return the receipt to the driver in the Taxios Driver application, allowing them to take a screenshot or print it if a Bluetooth printer is available or send via email to customer',
    vrpDirectInstructionError: 'Unfortunately, this VRP2 method isn`t available at the moment. Please contact us through the help section for more information.',
    vrpAppOpenAppMustBeInstalled: 'All drivers must have the VRP2 application installed on their phones from the app store',
    VCRSettings: 'Virtual cash register settings',
    vcrBelongsToType: 'Virtual cash register belongs to',
    vcrBelongsToTypeDescription: 'Wether the virtual cash register belongs to "Driver" or "Vehicle" - if "Driver" is selected each driver can be configured to use specific virtual cash register if "Vehicle" is selected each vehicle can be configured to use specific virtual cash register',
    vcrBelongsToDriver: 'Virtual cash register belongs to DRIVER, please make sure that each driver has the register assigned to them otherwise it will not work',
    vcrBelongsToVehicle: 'Virtual cash register belongs to VEHICLE, please make sure that each vehicle has the register assigned to them otherwise it will not work',
    RegistersAndCredentials: 'Registers, Credentials and VRP2 products',
    addNewRegister: 'Add new VRP2 register',
    vcrProducts: 'VRP 2 Products',
    vcrSyncError: 'Please sync VRP2 products first! If none products created in VRP2 none will be displayed to driver and whole process wont work',
    fixedFare: 'Zones fare',
    kmFare: 'Taxameter fare',
    slovakVrpFareType: 'Slovak VRP fare type',
    slovakVrpFareTypeDescription: 'Fare type - either Zones fare (fixed fare) or Taxameter fare (fare from taxameter)',
    openVcrProductsModal: 'Open VRP2 Products',
    vcrProductName: 'VRP 2 Product Name',
    vcrProductItemCode: 'VRP 2 Product Item Code',
    vcrProductValidFrom: 'VRP 2 Product Valid From',
    vcrProductValidTill: 'VRP 2 Product Valid Till',
    unlimited: 'Unlimited',
    useProductForTaxiosVRP: 'Use this product for VRP',
    usingThisProduct: 'This product is used for VRP',
    priceWithVat: 'Price with VAT',
    cannotUseThisProduct: 'Cannot use this product for VRP',
    modal: {
      titleEdit: 'Edit Virtual Cash Register',
      titleAdd: 'Add Virtual Cash Register',
      vcrName: 'Register Name (Internal)',
      vcrCredentialUser: 'VRP 2 User Name',
      vcrCredentialPass: 'VRP 2 Password',
      removeVCR: 'Remove Virtual Cash Register',
      taxiName: 'Taxi Name',
      receiptEmail: 'Email on receipt'
    }
  },
  fareSettings: {
    header: 'Fare Settings',
    name: 'Name',
    type: 'Type',
    cost: 'Cost',
    options: 'Options',
    fixedFare: 'Zones fare',
    kmFare: 'Taxameter (km) fare',
    driverEnters: 'Driver enters fare',
    faresEnabled: 'Fares enabled',
    slovakVrpFareType: 'Fare type',
    faresEnabledDescription: 'Whether to enable fares or not',
    slovakVrpFareTypeDescription: 'Fare type - either Zones fare (fixed fare) or Taximeter fare (fare from taximeter)',
    addFare: 'Add fare',
    modal: {
      titleEdit: 'Edit Fare',
      titleAdd: 'Add Fare',
      removeFare: 'Remove Fare'
    },
    fareName: 'Fare Name',
    fareCost: 'Fare Cost'
  },
  help: {
    header: 'Help',
    status: 'Status',
    socketStatus: 'Connection to server ',
    radioSocketStatus: 'Connection to radio server '
  },
  logout: {
    header: 'Logout',
    areYouSure: 'Are you sure you would like to log out?',
    logoutButton: 'Logout'
  },
  taxiosNotifications: {
    header: 'Notification settings',
    notificationsEmails: 'Your emails where we send email notifications',
    drivers: 'Drivers',
    notifyOnDriverLogin: 'Notify on driver login',
    notifyOnDriverLogout: 'Notify on driver logout',
    notifyWhenDriverOnBreak: 'Notify when driver starts break',
    notifyWhenDriverEndsBreak: 'Notify when driver ends break',
    notificationsEmailsForDrivers: 'Emails for driver notifications',
    orders: 'Orders',
    notifyOnMissedOrderCall: 'Notify when driver misses call',
    notifyOnMissedPlannedOrder: 'Notify when driver does not take action on a planned order',
    notifyOnPlannedOrderCreated: 'Notify when driver creates a planned order',
    notifyOnCompletedOrder: 'Notify when driver completes an order',
    taxiosSentinel: 'Taxios Sentinel',
    taxiosSentinelNotifications: 'Taxios Sentinel Notifications',
    taxiosSentinelNotificationsDescription: `
  Receive timely notifications from Taxios Sentinel to ensure your vehicle maintenance is on track. 
  <br /><br />
  <strong>Oil Change:</strong> Alerts will be sent at the following intervals, counting down to the next oil change:
  <ul>
    <li>1st notification at 2000 km remaining</li>
    <li>2nd notification at 1000 km remaining</li>
    <li>3rd notification at 100 km remaining</li>
    <li>Daily reminders will continue until the oil change is confirmed in Taxios Sentinel</li>
  </ul>
  <br />
  <strong>MOT/Emissions:</strong> Notifications will be sent:
  <ul>
    <li>1st notification 2 months before</li>
    <li>2nd notification 1 month before</li>
    <li>3rd notification 1 week before</li>
  </ul>
`
  }
}
